<template>
    <div class="keyboard-template">
        <div class="top-row-wrapper">
            <div id="q" @click="mobileClick"></div><div id="w" @click="mobileClick"></div><div id="e" @click="mobileClick"></div><div id="r" @click="mobileClick"></div>
            <div id="t" @click="mobileClick"></div><div id="y" @click="mobileClick"></div><div id="u" @click="mobileClick"></div><div id="i" @click="mobileClick"></div>
            <div id="o" @click="mobileClick"></div><div id="p" @click="mobileClick"></div>
        </div>
        <br>
        <div class="middle-row-wrapper">
            <div id="a" @click="mobileClick"></div><div id="s" @click="mobileClick"></div><div id="d" @click="mobileClick"></div><div id="f" @click="mobileClick"></div>
            <div id="g" @click="mobileClick"></div><div id="h" @click="mobileClick"></div><div id="j" @click="mobileClick"></div><div id="k" @click="mobileClick"></div>
            <div id="l" @click="mobileClick"></div>
        </div>
        <br>
            <div class="bottom-row-wrapper">
                <div id="Backspace" @click="mobileClick">Back</div>
                <div id="z" @click="mobileClick"></div><div id="x" @click="mobileClick"></div><div id="c" @click="mobileClick"></div><div id="v" @click="mobileClick"></div>
                <div id="b" @click="mobileClick"></div><div id="n" @click="mobileClick"></div><div id="m" @click="mobileClick"></div>
                <div id="Enter" @click="mobileClick">Enter</div>
            </div>
    </div>
</template>

<script>
    const WORD_LENGTH = 5;

    export default {
        name: 'ScreenKeyboard',
        props: ['currentWord'],
        watch: {
            currentWord(newData, oldData)
            {
                for (let i = 0; i < WORD_LENGTH; i++)
                {
                    if (newData[i].status == 2)
                    {
                        let currentLetterStr = newData[i].letter.toLowerCase();
                        document.getElementById(currentLetterStr).style = "background-color: lightgreen;";
                    }
                    else if (newData[i].status == 1)
                    {
                        if (oldData[i].status != 2)
                        {
                            let currentLetterStr = newData[i].letter.toLowerCase();
                            if (document.getElementById(currentLetterStr).style.backgroundColor != "lightgreen")
                               { document.getElementById(currentLetterStr).style = "background-color: yellow;"; }
                        }
                    }
                    else if (newData[i].status == 0)
                    {
                        if (oldData[i] != 2 && oldData[i] != 1)
                        {
                            let currentLetterStr = newData[i].letter.toLowerCase();
                            if (document.getElementById(currentLetterStr).style.backgroundColor != 'lightgreen' && document.getElementById(currentLetterStr).style.backgroundColor != 'yellow')
                                document.getElementById(currentLetterStr).style = "background-color: grey;";
                        }
                    }
                }
            },
        },

        mounted() {
            for (let i = 97; i < 123; i++)
            {
                let currentElement = document.getElementById(String.fromCharCode(i));
                currentElement.innerText = currentElement.id.toUpperCase();
            }
        },

        methods:
        {
            mobileClick: function(event)
            {
                console.log(event);
                console.log("emmiting");
                this.$emit('mobileClickEvent', event);
            }
        }
    }
</script>

<style>
.keyboard-template {
    padding-top: 8%;
    justify-content: center;
}

.keyboard-template > div > div{
    cursor: pointer;
}

.keyboard-template > .top-row-wrapper {
    display: grid;
    width: 380px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 5px;
}

.keyboard-template > .top-row-wrapper > div {
    font-style: bold;
    background-color: white;
    font-size: 2em;
    width: 33px;
}

.keyboard-template > .middle-row-wrapper {
    display: grid;
    width: 380px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 5px;
    margin-left: 5px;
}

.keyboard-template > .middle-row-wrapper > div {
    font-style: bold;
    background-color: white;
    font-size: 2em;
    width: 33px;
    justify-content: center;
}

.keyboard-template > .bottom-row-wrapper {
    display: grid;
    width: 380px;
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 3fr;
    grid-gap: 5px;
}

.keyboard-template > .bottom-row-wrapper > div {
    font-style: bold;
    background-color: white;
    font-size: 2em;
    width: 90%;
    justify-content: center;
}

</style>