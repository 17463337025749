<template>
  <div class="appcomp">
    <MyTitle title="WORDLE GAME" />
    <BoardArray />
  </div>
</template>

<script>
import MyTitle from './components/MyTitle.vue'
import BoardArray from './components/BoardArray.vue'

export default {
  name: 'App',
  components: {
      MyTitle,
      BoardArray,
  },
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 7%;
}
</style>
