<template>
    <div class="boardarraycomp">
        <div id="instructions">{{ instructions }}</div>
        <div id="board-row" v-for="(word, idx) in words" :key="idx">
            <div id="board-letter-wrapper">
                <div id="board-letter" :class="{whitebackground: letterArr.status=='', greenbackground: letterArr.status=='2', yellowbackground: letterArr.status=='1', greybackground: letterArr.status=='0'}" v-for="(letterArr, index) in word" :key="index">
                    {{ letterArr.letter }}
                </div>
            </div>
            <br>
        </div>
    </div>
    <ScreenKeyboard v-bind:currentWord="this.currentWordProp" @mobileClickEvent="mobileClickData"/>
</template>

<script>

import axios from 'axios';
import ScreenKeyboard from './ScreenKeyboard.vue';

    const MAX_GUESSES = 6;
    const WORD_LENGTH = 5;
    var firstIndex = 0;
    var secondIndex = 0;

    export default {
        name: 'BoardArray',
        components: {
            ScreenKeyboard,
        },
        data() {
            return {
                words: [],
                instructions: "Guess your first word!",
                wordid: 0,
                currentWordProp: [],
                watch: {
                    currentWordProp(newData, oldData) {
                        console.log(oldData[0] + " became " + newData[0]);
                    },
                }
            }
        },

        beforeMount() {
            for (let i = 0; i < MAX_GUESSES; i++)
            {
                this.words[i] = Array.apply(null, Array(WORD_LENGTH)).map(function() { return {letter: "", status: "", yellowUsed: false} });
            }
            this.currentWordProp = this.words;
        },

        mounted() {
            // https://smada.com/api/public/wordle/start
            // content-type: application/json
            // {"group_id":5}guess
            window.addEventListener("keydown", this.onKeyPress);

            axios.post('https://smada.com/api/public/wordle/start', {
                group_id: WORD_LENGTH
            })
            .then((response) => {
                this.wordid = response.data.word_id;
            }, (error) => {
                console.log("An error occured: " + error);
            })
        },

        unmounted() {
            window.removeEventListener("keydown", this.onKeyPress);
        },

        methods: {
            keyPressEvent: function(asciiValue, eventKey) {
                console.log("running keyPressEvent");
                this.instructions = "";
                document.getElementById("instructions").style = "";
                if (asciiValue == 8)
                {
                    if (secondIndex > 0)
                    {
                        secondIndex--;
                    }
                    this.words[firstIndex][secondIndex].letter = "";
                }
                else if (asciiValue == 13)
                {
                    if (secondIndex == WORD_LENGTH)
                    {
                        let userInputStr = "";
                        for (let i = 0; i < WORD_LENGTH; i++)
                        {
                            userInputStr += this.words[firstIndex][i].letter.toLowerCase();
                        }

                        axios.post('https://smada.com/api/public/wordle/guess', {
                            group_id: WORD_LENGTH,
                            word_id: this.wordid,
                            word: userInputStr,
                        })
                        .then(response => {
                            let greensFound = 0;
                            for (let i = 0; i < WORD_LENGTH; i++)
                            {
                                this.words[firstIndex][i].status = response.data.Status[i];
                                if(this.words[firstIndex][i].status == 2)
                                {
                                    greensFound++;
                                }
                            }
                            this.currentWordProp = this.words[firstIndex];
                            if (greensFound == WORD_LENGTH)
                            {
                                this.instructions = "You found the word!  Refresh to play again!";
                                window.removeEventListener("keydown", this.onKeyPress);
                            }
                            else if (firstIndex < MAX_GUESSES - 1)
                            {
                            firstIndex++;
                            secondIndex = 0;
                            }
                            else
                            {
                                axios.post("https://smada.com/api/public/wordle/final", {
                                    word_id: this.wordid,
                                    group_id: WORD_LENGTH,
                                })
                                .then (response => {
                                    console.log(response);
                                    this.instructions = "Game Over.  The word was " + response.data.Word + ". Refresh to try again.";
                                    window.removeEventListener("keydown", this.onKeyPress);
                                }, (error) => {
                                    this.instructions = "Game Over.  Sorry, we couldn't get the word for you.";
                                    console.log("Error retriving word: " + error);
                                    window.removeEventListener("keydown", this.onKeyPress);
                                })
                            }
                        }, (error) => {
                            if (error.response.status == 404)
                            {
                                document.getElementById("instructions").style = "color: red;";
                                this.instructions = "Invalid Word"
                            }
                        })
                    }
                    else {
                        document.getElementById("instructions").style = "color: red;";
                        this.instructions = "Please enter a 5 letter word";
                    }
                }
                else
                {
                    if (secondIndex < WORD_LENGTH && asciiValue >= 65 && asciiValue <= 90)
                    {
                        this.words[firstIndex][secondIndex].letter = eventKey.toUpperCase();
                        secondIndex++;
                    }
                }
            },

            onKeyPress: function(event) {
                console.log(event.which, event.key);
                this.keyPressEvent(event.which, event.key);
            },

            mobileClickData(passedEvent) {
                console.log("on screen key clicked");
                if (passedEvent.target.id == 'Enter')
                    { this.keyPressEvent(13, 'Enter'); }
                else if (passedEvent.target.id == 'Backspace')
                    { this.keyPressEvent(8, 'Backspace'); }
                else
                    { this.keyPressEvent(passedEvent.target.id.charCodeAt(0) - 32 /*make it uppercase*/, passedEvent.target.id.toUpperCase()); }
            }
        }
    }
</script>

<style>
    body {
        background-color: rgb(83, 184, 150);
        display: flex;
        justify-content: center;
        padding-left: 5vw;
    }

    #instructions {
        height: 50px;
        font-size: 1.75rem;
        max-width: 450px;
        overflow-wrap: normal;
    }

    #board-row {
        width: 380px;
        height: 60px;
    }

    #board-letter-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 0px;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    #board-letter {
        font-size: 2em;
        font-weight: 600;
        height: 60px;
        width: 75%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 3px 3px 5px black;
    }

    .whitebackground {
        background-color: white;
    }

    .greybackground {
        background-color: grey;
    }

    .yellowbackground {
        background-color: yellow;
    }

    .greenbackground {
        background-color: lightgreen;
    }
</style>